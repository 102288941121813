/**
* Generated automatically at built-time (2025-01-31T10:18:34.624Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoorkueche-kaufen-ratgeber",templateKey: "sites/105-ed236393-6088-44c5-9fd1-2f0fc0ca6a25"};