/**
* Generated automatically at built-time (2025-01-31T10:18:34.602Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-stainless-collection-outdoorkueche",templateKey: "sites/105-7d472427-53b1-49ec-8c94-fb36eeb8cbcf"};