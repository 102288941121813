/**
* Generated automatically at built-time (2025-01-31T10:18:34.357Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "induplus-mobile-outdoorkueche",templateKey: "sites/105-89ac1e6c-6723-497c-8298-e6a9fa19a199"};