/**
* Generated automatically at built-time (2025-01-31T10:18:34.404Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "pflege-outdoorkueche-ratgeber",templateKey: "sites/105-30483cdd-9d92-47a5-b07e-60c39cabf750"};